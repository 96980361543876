import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [{
        path: "/Nosotros",
        name: "Nosotros",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/Nosotros.vue"),
    },
    {
        path: "/:section",
        name: "Inicio",
        component: Home,
    },
    {
        path: "/Tienda/all",
        name: "Tienda",
        props: (route) => ({ filtro: route.query.filtro }),
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/Tienda.vue"),
    },
    {
        path: "/Tienda/all/:key",
        name: "Producto",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/Producto.vue"),
    },

    {
        path: "/Carrito/all",
        name: "Carrito",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ "../views/Carrito.vue"),
    },
    { path: "/", redirect: "/Inicio" },
    { path: "*", redirect: "/Inicio" },
];

const router = new VueRouter({
    routes,
});

export default router;