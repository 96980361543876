<template>
  <div
    style="text-align: center; width: 100%"
    v-if="pagina != undefined && pagina.cuadro1 != undefined"
  >
    <div
      style="height: 70vh; position: relative; display: flex; align-items: center"
    >
      <div style="width: 100%; height: 100%; position: absolute">
        <v-img
          position="left bottom"
          :src="require('@/assets/fondo1.jpg')"
          style="width: 100%; height: 100%"
        ></v-img>
      </div>

      <div
        v-animate-onscroll="{ up: 'animated fadeIn', down: 'animated fadeOut' }"
        :style="
          `
          padding: 35px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: left;
          height: 40%;
          background-color: rgba(255, 255, 255, 0.9);
          position: absolute;` + (!isMobile ? 'margin-top: -175px;' : '')
        "
      >
        <span class="text-h5 text-md-h4" style="font-weight: bold;">
          {{ pagina.texto1 }}
        </span>
        <span class="text-subtitle-1 text-md-h6" style="margin-top: 15px;">
          <span style="font-family:'Yanone Kaffeesatz'">{{
            pagina.texto2
          }}</span>
        </span>
        <div
          style="margin-top: 15px; font-weight: 500"
          class="text-md-h4 font-italic "
        >
          <span style="font-family: 'Satisfy'">{{ pagina.texto3 }}</span>
        </div>
      </div>
    </div>
    <div
      class="font-italic text-h5 text-md-h4"
      :style="
        'height: ' +
          (isMobile ? '200px' : '100px') +
          ';' +
          'width: 100%;' +
          'background-color: #A8713A;' +
          'color: white;' +
          'display: flex;' +
          'align-items: center;' +
          'justify-content: center;' +
          'padding: 25px;'
      "
    >
      <span style="font-family: 'Satisfy';font-weight:normal"
        >{{ pagina.banner1 }}
        <br v-if="isMobile" />
        <v-btn
          color="white"
          @click="
            $parent.$parent.$parent.cambiaTab({
              nombre: 'Tienda',
              ruta: '/Tienda/all',
            })
          "
          :style="
            'margin-left: 15px;  color: #438019; font-weight: 700;' +
              (isMobile ? 'margin-top: 10px;' : '')
          "
        >
          <span
            style="font-family: 'Roboto'; font-style: normal; margin-right: 5px"
            >Ir a la Tienda</span
          >
          <v-icon size="18">mdi-shopping-outline</v-icon>
        </v-btn>
      </span>
    </div>
    <div style="width: 100%; margin-top: 50px">
      <v-container>
        <v-row>
          <v-col cols="12" :md="isMed ? '6' : '3'">
            <div
              class="centrar"
              style="opacity:0;"
              v-animate-onscroll="'animated fadeInUp'"
            >
              <div
                class="centrar"
                style="
                  //border: #666666 3px solid;
                  padding: 10px;
                  border-radius: 10px;
                  color: #A8713A;
                "
              >
                <img
                  :src="require('@/assets/h1.svg')"
                  alt="h1"
                  :width="isMobile ? '30px' : '50px'"
                />
                <span
                  class="font-italic text-h6 text-md-h5"
                  :style="
                    'margin-left: 5px; font-weight: 600;' +
                      (isMobile ? '' : 'width:80%')
                  "
                  >{{ pagina.cuadro1.titulo.toUpperCase() }}</span
                >
              </div>
              <span class="text-md-body-1" style="width: 80%">
                {{ pagina.cuadro1.descrip }}
              </span>
            </div>
          </v-col>
          <v-col cols="12" :md="isMed ? '6' : '3'">
            <div
              class="centrar"
              style="opacity:0;"
              v-animate-onscroll="{
                down: 'animated fadeInUp',
              }"
            >
              <div
                class="centrar"
                style="
                  //border: #666666 3px solid;
                  padding: 10px;
                  border-radius: 10px;
                  color: #A8713A;
                "
              >
                <img
                  :src="require('@/assets/h2.svg')"
                  alt="h2"
                  :width="isMobile ? '30px' : '50px'"
                />
                <span
                  class="font-italic text-h6 text-md-h5"
                  :style="
                    'margin-left: 5px; font-weight: 600;' +
                      (isMobile ? '' : 'width:80%')
                  "
                  >{{ pagina.cuadro2.titulo.toUpperCase() }}</span
                >
              </div>
              <span class="text-md-body-1" style="width: 80%">
                {{ pagina.cuadro2.descrip }}
              </span>
            </div>
          </v-col>
          <v-col cols="12" :md="isMed ? '6' : '3'">
            <div
              class="centrar"
              style="opacity:0;"
              v-animate-onscroll="{
                down: 'animated fadeInUp',
              }"
            >
              <div
                class="centrar"
                style="
                  //border: #666666 3px solid;
                  padding: 10px;
                  border-radius: 10px;
                  color: #A8713A;
                "
              >
                <img
                  :src="require('@/assets/h3.svg')"
                  alt="h3"
                  :width="isMobile ? '30px' : '50px'"
                />
                <span
                  class="font-italic text-h6 text-md-h5"
                  :style="
                    'margin-right: ' +
                      (isMobile ? '5' : '15') +
                      'px; font-weight: 600'
                  "
                  >{{ pagina.cuadro3.titulo.toUpperCase() }}</span
                >
              </div>
              <span class="text-md-body-1" style="width: 80%">
                {{ pagina.cuadro3.descrip }}
              </span>
            </div>
          </v-col>
          <v-col cols="12" :md="isMed ? '6' : '3'">
            <div
              class="centrar"
              style="opacity:0;"
              v-animate-onscroll="{
                down: 'animated fadeInUp',
              }"
            >
              <div
                class="centrar"
                style="
                  //border: #666666 3px solid;
                  padding: 10px;
                  border-radius: 10px;
                  color: #A8713A;
                  width: 90%;
                "
              >
                <img
                  :src="require('@/assets/h4.svg')"
                  alt="h4"
                  :width="isMobile ? '30px' : '50px'"
                />
                <span
                  class="font-italic text-h6 text-md-h5"
                  :style="
                    'margin-right: ' +
                      (isMobile ? '5' : '15') +
                      'px; font-weight: 600'
                  "
                  >{{ pagina.cuadro4.titulo.toUpperCase() }}</span
                >
              </div>
              <span class="text-md-body-1" style="width: 80%">
                {{ pagina.cuadro4.descrip }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div
      style="width: 100%; height: 600px; position: relative; margin-top: 50px"
    >
      <v-img
        :src="require('@/assets/foto.jpg')"
        height="100%"
        :position="'center ' + (isMobile ? 'center' : 'top')"
        style="position: absolute"
      ></v-img>
      <div
        class="centrar"
        :style="
          'position: absolute;width: 100%;height: 100%;padding-bottom: 50px;align-items: flex-end;' +
            (isMobile
              ? 'justify-content: center; '
              : 'justify-content: flex-end;')
        "
      >
        <div
          v-animate-onscroll="'animated fadeInRight'"
          :style="
            `opacity:0;
            padding: 20px;
            background-color: rgba(192,129,66, 0.9);
            color: white;
            ${
              isMobile
                ? 'border-radius: 0px 0px 0px 0px;'
                : 'border-radius: 15px 0px 0px 15px;'
            }            
            //border: white 3px solid;
          `
          "
          class="centrar font-italic"
        >
          <span class="text-subtitle-1 font-bold">{{
            pagina.banner2.texto1
          }}</span>
          <span class="text-h5">{{ pagina.banner2.texto2 }}</span>
          <span class="text-subtitle-1" style="align-self: flex-end">{{
            pagina.banner2.texto3
          }}</span>
        </div>
      </div>
    </div>
    <div style="width: 100%; margin-top: 50px">
      <span
        class="font-weight-bold text-h5 text-md-h4"
        :style="'color: ' + colors[0]"
        >{{ pagina.seccion3.titulo }}</span
      >
      <v-container style="margin-top: 10px">
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="3"
            v-for="(paso, i) in pagina.seccion3.pasos"
            :key="i"
          >
            <div
              class="centrar"
              style="opacity:0"
              v-animate-onscroll="{
                down: 'animated fadeInLeft',
              }"
            >
              <div
                class="centrar"
                style="
                  border: #438019 4px solid;
                  border-radius: 50%;
                  width: 70px;
                  height: 70px;
                "
              >
                <v-icon class="text-h5 text-md-h4" color="#af5116">
                  mdi-{{ paso.icono }}
                </v-icon>
              </div>
              <span
                class="font-weight-black text-h6 text-md-h5"
                style="margin-top: 15px"
              >
                {{ paso.titulo.toUpperCase() }}
              </span>
              <span class="text-md-h6" style="margin-top: 5px">
                {{ paso.descrip }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { paginaInfo, colors } from "../main";
import { onSnapshot } from "firebase/firestore";

export default {
  name: "Home",
  data() {
    return {
      colors: colors,
      pagina: { seccion3: { pasos: [] } },
      isMobile: this.Vue_window.screen.width <= 960,
      isMed: this.Vue_window.screen.width <= 1250,
      p1: false,
    };
  },
  mounted() {
    const unsub = onSnapshot(paginaInfo, (doc) => {
      this.pagina = doc.data();
    });
  },
  methods: {
    cargaData() {},
  },
};
</script>
