import Vue from "vue";
import App from "./App.vue";
import VueAnimateOnScroll from "vue-animate-onscroll";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc } from "firebase/firestore";
import { getAuth, signInAnonymously, deleteUser } from "firebase/auth";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueCookies from "vue-cookies";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

const firebaseConfig = {
    apiKey: "AIzaSyAyvrPJiye3ctHsacK7bscDWO0vzUTWp3Y",
    authDomain: "verdegranelec.firebaseapp.com",
    projectId: "verdegranelec",
    storageBucket: "verdegranelec.appspot.com",
    messagingSenderId: "451756136337",
    appId: "1:451756136337:web:007d351ac2257419bfec30",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getFirestore(app);
const auth = getAuth(app);
const paginaInfo = doc(database, "pagina", "667gI7Pi7W6i6D9mIXER");
const categoriasDB = collection(database, "categorias");
const cuponesDB = collection(database, "cupones");
const productosDB = collection(database, "productos");

async function logAnon() {
    await signInAnonymously(auth)
        .then(() => {
            console.log("BIENVENIDO!");
            return true;
        })
        .catch((error) => {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.log("ERROR (" + errorCode + "): " + errorMessage);
            return false;
        });
}

logAnon();

Vue.config.productionTip = false;
Vue.prototype.Vue_window = window;
Vue.use(VueAnimateOnScroll);

setTimeout(() => {
    new Vue({
        vuetify,
        router,
        VueCookies,
        render: (h) => h(App),
    }).$mount("#app");
}, 750);

//Para eliminar usuario anonimo cuando sale de la página
window.onbeforeunload = function() {
    deleteUser(auth.currentUser)
        .then(() => {
            console.log("Successfully deleted user");
        })
        .catch((error) => {
            console.log("Error deleting user:", error);
        });
};

const colors = ["#438019", "#A8713A", "#666666", "#8A8A8A"];

export { paginaInfo, categoriasDB, cuponesDB, productosDB, colors };