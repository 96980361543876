<template>
  <v-app>
    <v-main>
      <div id="app" ref="app">
        <div ref="Inicio" style="height: 0.5px; width: 100%"></div>
        <div>
          <v-app-bar
            id="home-app-bar"
            app
            elevation="1"
            elevate-on-scroll
            shrink-on-scroll
            min-height="70"
            :height="isMobile ? '70' : '125'"
            width="100vw"
            style="background-color: #F8F8F8"
            fade-img-on-scroll
            ref="menu"
          >
            <template v-slot:img="{}">
              <div
                class="centrar hidden-sm-and-down"
                style="height: 50%; width: 100%; text-align: center"
              >
                <div
                  class="centrar"
                  style="flex-direction: row; height: 100%; width: 75%; min-width: 600px"
                >
                  <div
                    style="
                      height: 100%;
                      width: 5%;
                      background-color: #438019;
                      clip-path: polygon(0% 100%, 100% 0%, 100% 100%, 0% 100%);
                    "
                  ></div>
                  <div
                    class="centrar"
                    style="
                      width: 100%;
                      height: 100%;
                      background-color: #438019;
                      font-family: 'Yanone Kaffeesatz';
                      color: white;
                      letter-spacing: 3px;
                      margin-left: -1px;
                      margin-right: -1px;
                    "
                  >
                    {{ pagina.topQuote }}
                  </div>
                  <div
                    style="
                      height: 100%;
                      width: 5%;
                      background-color: #438019;
                      clip-path: polygon(0% 0%, 100% 100%, 100% 100%, 0% 100%);
                    "
                  ></div>
                </div>
              </div>
            </template>
            <v-app-bar-nav-icon
              class="hidden-md-and-up"
              @click="drawer = true"
              style="width: 15%; max-width: 50px"
            />
            <div style="height: inherit" class="hidden-sm-and-down">
              <img
                :src="require('@/assets/logo.png')"
                style="height: calc(100% - 15px); min-height: 60px; margin-left: 20px"
              />
            </div>
            <v-tabs
              class="hidden-sm-and-down"
              background-color="transparent"
              color="black"
              style="display: flex; justify-content: flex-end;align-items:flex-end;width:80%; height: inherit"
              :value="slider"
            >
              <v-tab
                v-for="tab in tabs"
                :key="tab.nombre"
                class="font-weight-bold"
                min-width="96"
                text
                :ripple="false"
                style="margin-right: 30px; height: inherit; margin-top: auto; margin-bottom: 15px;"
                @click="cambiaTab(tab)"
                ref="tabs"
              >
                <span style="font-size: 18px">
                  <span
                    v-if="tab.nombre != 'Tienda' && tab.nombre != 'Nosotros'"
                  >
                    {{ tab.nombre }}
                  </span>
                  <span v-else>
                    {{ tab.nombre }}
                  </span>
                </span>
              </v-tab>
            </v-tabs>

            <!-- <v-btn icon>
				 <v-icon>mdi-heart</v-icon>
			   </v-btn> -->
            <div
              :style="
                `
                font-size: 15px;
                height: 100%;
                width: ${isMobile ? '100%' : '40%'};
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
              `
              "
            >
              <div style="height: 35px">
                <v-badge inline color="#438019" :content="carrito_num + ''">
                  <v-btn
                    small
                    icon
                    @click="
                      cambiaTab(
                        carrito_num == 0
                          ? { nombre: 'Tienda', ruta: '/Tienda/all' }
                          : { nombre: 'Carrito', ruta: '/Carrito/all' }
                      )
                    "
                  >
                    <v-icon>mdi-shopping-outline</v-icon>
                  </v-btn>
                  <span class="hidden-sm-and-down"
                    >$ {{ parseFloat(total_carrito).toFixed(2) }}</span
                  >
                </v-badge>
              </div>
              <div
                style="height: 35px; margin-left: 15px; display: flex; margin-right: -80px"
              >
                <v-btn
                  small
                  icon
                  @click="
                    cambiaTab(
                      carrito_num == 0
                        ? { nombre: 'Tienda', ruta: '/Tienda/all' }
                        : { nombre: 'Carrito', ruta: '/Carrito/all' }
                    )
                  "
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
                <v-text-field
                  v-model="buscar"
                  label="Buscar en la Tienda"
                  color="#438019"
                  style="
                    transform: scale(0.675);
                    transform-origin: left;
                    font-size: 20px;
                    margin-top: -10px;
                    width: '100%';
                  "
                  @click="cambiaTab({ nombre: 'Tienda', ruta: '/Tienda/all' })"
                  solo
                  clearable
                ></v-text-field>
              </div>
            </div>
          </v-app-bar>
          <v-navigation-drawer v-model="drawer" fixed temporary>
            <v-list nav flat>
              <v-list-item-group active-class="deep-grey--text text--accent-4">
                <v-list-item
                  v-for="tab in tabs"
                  :key="tab.nombre"
                  @click="
                    cambiaTab(tab);
                    drawer = false;
                  "
                >
                  <v-list-item-icon>
                    <v-icon>{{ tab.icono }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ tab.nombre }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>
        </div>
        <div style="min-height: 800px">
          <v-scroll-x-transition mode="out-in">
            <router-view ref="main" />
          </v-scroll-x-transition>
        </div>
        <div ref="Contacto" style="height: 0.5px; width: 100%"></div>
        <div style="width: 100%; display: flex">
          <img
            :src="require('@/assets/hoja3.svg')"
            alt="separador"
            :style="'width: ' + (isMobile ? '30' : '45') + '%; height: 100px'"
          />
          <img
            :src="require('@/assets/hojas.svg')"
            alt="separador"
            :style="
              'max-width: ' +
                (isMobile ? '40' : '10') +
                '%; height: 100px;margin-top: 30px'
            "
          />
          <img
            :src="require('@/assets/hoja3.svg')"
            alt="separador"
            :style="'width: ' + (isMobile ? '30' : '45') + '%; height: 100px'"
          />
        </div>
        <div>
          <v-footer dark padless>
            <v-card
              flat
              tile
              class="text-center"
              color="#333333"
              style="width: 100%"
            >
              <v-card color="#345E17" style="width: 100%">
                <v-card-text>
                  <v-row
                    style="width: 100%; display: flex; justify-content: center"
                  >
                    <v-col cols="12" md="6" sm="12" style="padding: 0">
                      <div class="centrar">
                        <div
                          style="
                            color: white;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            text-align: left;
                            padding: 20px;
                          "
                        >
                          <span
                            class="text-subtitle-1 text-md-h5"
                            style="opacity:0"
                            v-animate-onscroll="'animated fadeInUp'"
                            >Nos encuentras en:</span
                          >
                          <span
                            class="text-subtitle-1 text-md-h5"
                            style="opacity:0"
                            v-animate-onscroll="'animated fadeInUp'"
                            ><b>QUITO</b></span
                          >
                          <span
                            class="text-subtitle-2 text-md-h6"
                            style="opacity:0"
                            v-animate-onscroll="'animated fadeInUp'"
                            ><v-icon style="margin-right: 3px;margin-top: -5px"
                              >mdi-map-marker</v-icon
                            >Plaza del Rancho, local 20
                          </span>
                          <a
                            :href="waLink"
                            v-animate-onscroll="'animated fadeInUp'"
                            style="text-decoration: none; color: white;opacity:0"
                            class="text-subtitle-2 text-md-h6"
                            ><v-icon style="margin-left: 3px;margin-top: -5px"
                              >mdi-whatsapp</v-icon
                            >
                            +{{ pagina.telefono }}
                          </a>
                        </div>
                        <div style="width: 100%; height: 100%;margin-left:20px">
                          <div style="width: 100%">
                            <iframe
                              width="100%"
                              height="200"
                              frameborder="0"
                              scrolling="no"
                              marginheight="0"
                              marginwidth="0"
                              src="https://maps.google.com/maps?width=100%25&amp;height=200&amp;hl=en&amp;q=Verde%20Granel%20EcoMarket,Eugenio%20Espejo,%20Quito%20170157+(Verde%20Granel%20-EcoMarket-)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <div
                        class="centrar"
                        style="height: 100%; margin-right: -25px"
                      >
                        <img
                          :src="require('@/assets/logo_white.png')"
                          width="50%"
                          style="max-width: 200px; margin-bottom: 10px"
                        />
                        <div
                          v-for="soc in social"
                          :key="soc.icon"
                          style="padding: 2.5px"
                        >
                          <a
                            :href="soc.link"
                            target="_blank"
                            style="text-decoration: none; color: white"
                          >
                            <v-icon style="margin-right: 5px">{{
                              soc.icon
                            }}</v-icon>
                            <span>{{ soc.text }}</span>
                          </a>
                        </div>
                        <v-btn
                          :color="colors[1]"
                          href="mailto:info.verdegranel@gmail.com?Subject=Quiero%20ser%20Proveedor"
                        >
                          QUIERO SER PROVEEDOR
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-divider></v-divider>

              <v-card-text class="white--text">
                <strong>Verde Granel EcoMarket ® </strong
                >{{ new Date().getFullYear() }} - Desarrollado por MonkeyTek
              </v-card-text>
            </v-card>
          </v-footer>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { paginaInfo, colors } from "./main";
import { onSnapshot } from "firebase/firestore";

export default {
  name: "App",
  metaInfo: {
    htmlAttrs: { lang: "es" },
  },
  data() {
    return {
      slider: 0,
      colors: colors,
      drawer: false,
      buscar: "",
      inicio: "Inicio",
      tabs: [
        { nombre: "Inicio", ruta: "/Inicio", icono: "mdi-home" },
        {
          nombre: "Nosotros",
          ruta: "/Nosotros",
          icono: "mdi-account-multiple-check",
        },
        { nombre: "Tienda", ruta: "/Tienda/all", icono: "mdi-shopping" },
        { nombre: "Contacto", ruta: "/Contacto", icono: "mdi-account-box" },
      ],
      carrito: [],
      carrito_num: 0,
      total_carrito: 0,
      pagina: {},
      isMobile: this.Vue_window.screen.width <= 960,
      social: [
        {
          icon: "mdi-instagram",
          text: "@VerdeGranelEc",
          link: "https://www.instagram.com/verdegranelec/?hl=es",
        },
        {
          icon: "mdi-facebook",
          text: "Verde Granel Ecomarket",
          link: "https://www.facebook.com/2003488873047701/",
        },
        {
          icon: "mdi-mail",
          text: "info.verdegranel@gmail.com",
          link:
            "mailto:info.verdegranel@gmail.com?Subject=Quiero%20ser%20Proveedor",
        },
      ],
      waLink:
        "https://api.whatsapp.com/send/?phone=593988981129&text=Hola Verde Granel -EcoMarket-",
      map: undefined,
      isLoading: true,
    };
  },
  watch: {
    $route(to, from) {
      //console.log(this.$route.params.section + "-" + this.$route.name);
      if (to.name == "Nosotros") {
        document.title = "Nosotros";
        this.slider = 1;
      } else if (
        to.name == "Tienda" ||
        to.name == "Producto" ||
        to.name == "Carrito"
      ) {
        document.title = to.name;
        this.slider = 2;
      } else {
        if (to.params.section == "Inicio") {
          this.slider = 0;
        } else if (to.params.section == "Contacto") {
          this.slider = 3;
        }
        document.title = to.params.section;
      }
    },
  },
  mounted() {
    const unsub = onSnapshot(paginaInfo, (doc) => {
      this.pagina = doc.data();
    });
  },
  created() {
    this.updateCarrito();
    setTimeout(() => {
      this.tabs.forEach((tab, i) => {
        //console.log(this.$route.params.section + "-" + this.$route.name);
        if (this.$route.params.section == undefined) {
          if (
            this.$route.name == "Tienda" ||
            this.$route.name == "Producto" ||
            this.$route.name == "Bolsa"
          ) {
            this.slider = 2;
          } else if (this.$route.name == "Nosotros") {
            this.slider = 1;
          }
        } else if (this.$route.params.section == "Inicio") {
          document.title = "Inicio";
          this.scrollear("Inicio");
        } else if (this.$route.params.section == "Contacto") {
          document.title = "Contacto";
          this.scrollear("Contacto");
        }
        if (tab.nombre == this.$route.params.section) {
          this.slider = i;
        }
      });
    }, 500);
  },
  methods: {
    cambiaTab(tab) {
      document.title = tab.nombre;
      this.$router
        .push(tab.ruta)
        .then(() => {
          this.scrollear(tab.nombre);
        })
        .catch(() => {
          this.scrollear(tab.nombre);
        });
    },
    options() {
      return {
        duration: 700,
        offset: 0,
        easing: "easeInOutCubic",
      };
    },
    scrollear(tag) {
      if (tag != "Inicio" && tag != "Contacto") {
        if (this.$refs.main == undefined) {
          setTimeout(() => {
            this.$vuetify.goTo(this.$refs.main.$refs[tag], this.options);
          }, 500);
        } else {
          setTimeout(() => {
            this.$vuetify.goTo(this.$refs.main.$refs[tag], this.options);
          }, 500);
        }
      } else {
        setTimeout(() => {
          this.$vuetify.goTo(this.$refs[tag], this.options);
        }, 500);
      }
    },
    updateCarrito() {
      let productos = JSON.parse(window.$cookies.get("productos"));
      this.carrito = productos == null ? [] : productos;
      this.carrito_num = this.carrito.length;
      let total = 0.0;
      this.carrito.forEach((p) => {
        total += parseFloat(p.precio) * parseFloat(p.cantidad);
      });
      this.total_carrito = total;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fleur+De+Leah&family=Satisfy&display=swap");
body {
  font-family: "Roboto", sans-serif;
}
.centrar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.opaco-producto {
  opacity: 0.85;
}
.v-card {
  transition: opacity 0.2s ease-in-out;
}
</style>

<style lang="sass">
.v-tab
  &::before
    display: none
.hidden-sm-and-down
  @media   only   screen   and   (max-width:   959px)
    display: none !important
.hidden-md-and-up
  @media   only   screen   and   (min-width:   960px)
    display: none !important
.hidden-xs-and-down
  @media   only   screen   and   (max-width:   600px)
    display: none !important
.hidden-xs-and-up
  @media   only   screen   and   (min-width:   601px)
    display: none !important
</style>
