var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pagina != undefined && _vm.pagina.cuadro1 != undefined)?_c('div',{staticStyle:{"text-align":"center","width":"100%"}},[_c('div',{staticStyle:{"height":"70vh","position":"relative","display":"flex","align-items":"center"}},[_c('div',{staticStyle:{"width":"100%","height":"100%","position":"absolute"}},[_c('v-img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"position":"left bottom","src":require('@/assets/fondo1.jpg')}})],1),_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:({ up: 'animated fadeIn', down: 'animated fadeOut' }),expression:"{ up: 'animated fadeIn', down: 'animated fadeOut' }"}],style:("\n        padding: 35px;\n        display: flex;\n        flex-direction: column;\n        justify-content: center;\n        text-align: left;\n        height: 40%;\n        background-color: rgba(255, 255, 255, 0.9);\n        position: absolute;" + (!_vm.isMobile ? 'margin-top: -175px;' : ''))},[_c('span',{staticClass:"text-h5 text-md-h4",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.pagina.texto1)+" ")]),_c('span',{staticClass:"text-subtitle-1 text-md-h6",staticStyle:{"margin-top":"15px"}},[_c('span',{staticStyle:{"font-family":"'Yanone Kaffeesatz'"}},[_vm._v(_vm._s(_vm.pagina.texto2))])]),_c('div',{staticClass:"text-md-h4 font-italic ",staticStyle:{"margin-top":"15px","font-weight":"500"}},[_c('span',{staticStyle:{"font-family":"'Satisfy'"}},[_vm._v(_vm._s(_vm.pagina.texto3))])])])]),_c('div',{staticClass:"font-italic text-h5 text-md-h4",style:('height: ' +
        (_vm.isMobile ? '200px' : '100px') +
        ';' +
        'width: 100%;' +
        'background-color: #A8713A;' +
        'color: white;' +
        'display: flex;' +
        'align-items: center;' +
        'justify-content: center;' +
        'padding: 25px;')},[_c('span',{staticStyle:{"font-family":"'Satisfy'","font-weight":"normal"}},[_vm._v(_vm._s(_vm.pagina.banner1)+" "),(_vm.isMobile)?_c('br'):_vm._e(),_c('v-btn',{style:('margin-left: 15px;  color: #438019; font-weight: 700;' +
            (_vm.isMobile ? 'margin-top: 10px;' : '')),attrs:{"color":"white"},on:{"click":function($event){return _vm.$parent.$parent.$parent.cambiaTab({
            nombre: 'Tienda',
            ruta: '/Tienda/all',
          })}}},[_c('span',{staticStyle:{"font-family":"'Roboto'","font-style":"normal","margin-right":"5px"}},[_vm._v("Ir a la Tienda")]),_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-shopping-outline")])],1)],1)]),_c('div',{staticStyle:{"width":"100%","margin-top":"50px"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":_vm.isMed ? '6' : '3'}},[_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:('animated fadeInUp'),expression:"'animated fadeInUp'"}],staticClass:"centrar",staticStyle:{"opacity":"0"}},[_c('div',{staticClass:"centrar",staticStyle:{"//border":"#666666 3px solid","padding":"10px","border-radius":"10px","color":"#A8713A"}},[_c('img',{attrs:{"src":require('@/assets/h1.svg'),"alt":"h1","width":_vm.isMobile ? '30px' : '50px'}}),_c('span',{staticClass:"font-italic text-h6 text-md-h5",style:('margin-left: 5px; font-weight: 600;' +
                    (_vm.isMobile ? '' : 'width:80%'))},[_vm._v(_vm._s(_vm.pagina.cuadro1.titulo.toUpperCase()))])]),_c('span',{staticClass:"text-md-body-1",staticStyle:{"width":"80%"}},[_vm._v(" "+_vm._s(_vm.pagina.cuadro1.descrip)+" ")])])]),_c('v-col',{attrs:{"cols":"12","md":_vm.isMed ? '6' : '3'}},[_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:({
              down: 'animated fadeInUp',
            }),expression:"{\n              down: 'animated fadeInUp',\n            }"}],staticClass:"centrar",staticStyle:{"opacity":"0"}},[_c('div',{staticClass:"centrar",staticStyle:{"//border":"#666666 3px solid","padding":"10px","border-radius":"10px","color":"#A8713A"}},[_c('img',{attrs:{"src":require('@/assets/h2.svg'),"alt":"h2","width":_vm.isMobile ? '30px' : '50px'}}),_c('span',{staticClass:"font-italic text-h6 text-md-h5",style:('margin-left: 5px; font-weight: 600;' +
                    (_vm.isMobile ? '' : 'width:80%'))},[_vm._v(_vm._s(_vm.pagina.cuadro2.titulo.toUpperCase()))])]),_c('span',{staticClass:"text-md-body-1",staticStyle:{"width":"80%"}},[_vm._v(" "+_vm._s(_vm.pagina.cuadro2.descrip)+" ")])])]),_c('v-col',{attrs:{"cols":"12","md":_vm.isMed ? '6' : '3'}},[_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:({
              down: 'animated fadeInUp',
            }),expression:"{\n              down: 'animated fadeInUp',\n            }"}],staticClass:"centrar",staticStyle:{"opacity":"0"}},[_c('div',{staticClass:"centrar",staticStyle:{"//border":"#666666 3px solid","padding":"10px","border-radius":"10px","color":"#A8713A"}},[_c('img',{attrs:{"src":require('@/assets/h3.svg'),"alt":"h3","width":_vm.isMobile ? '30px' : '50px'}}),_c('span',{staticClass:"font-italic text-h6 text-md-h5",style:('margin-right: ' +
                    (_vm.isMobile ? '5' : '15') +
                    'px; font-weight: 600')},[_vm._v(_vm._s(_vm.pagina.cuadro3.titulo.toUpperCase()))])]),_c('span',{staticClass:"text-md-body-1",staticStyle:{"width":"80%"}},[_vm._v(" "+_vm._s(_vm.pagina.cuadro3.descrip)+" ")])])]),_c('v-col',{attrs:{"cols":"12","md":_vm.isMed ? '6' : '3'}},[_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:({
              down: 'animated fadeInUp',
            }),expression:"{\n              down: 'animated fadeInUp',\n            }"}],staticClass:"centrar",staticStyle:{"opacity":"0"}},[_c('div',{staticClass:"centrar",staticStyle:{"//border":"#666666 3px solid","padding":"10px","border-radius":"10px","color":"#A8713A","width":"90%"}},[_c('img',{attrs:{"src":require('@/assets/h4.svg'),"alt":"h4","width":_vm.isMobile ? '30px' : '50px'}}),_c('span',{staticClass:"font-italic text-h6 text-md-h5",style:('margin-right: ' +
                    (_vm.isMobile ? '5' : '15') +
                    'px; font-weight: 600')},[_vm._v(_vm._s(_vm.pagina.cuadro4.titulo.toUpperCase()))])]),_c('span',{staticClass:"text-md-body-1",staticStyle:{"width":"80%"}},[_vm._v(" "+_vm._s(_vm.pagina.cuadro4.descrip)+" ")])])])],1)],1)],1),_c('div',{staticStyle:{"width":"100%","height":"600px","position":"relative","margin-top":"50px"}},[_c('v-img',{staticStyle:{"position":"absolute"},attrs:{"src":require('@/assets/foto.jpg'),"height":"100%","position":'center ' + (_vm.isMobile ? 'center' : 'top')}}),_c('div',{staticClass:"centrar",style:('position: absolute;width: 100%;height: 100%;padding-bottom: 50px;align-items: flex-end;' +
          (_vm.isMobile
            ? 'justify-content: center; '
            : 'justify-content: flex-end;'))},[_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:('animated fadeInRight'),expression:"'animated fadeInRight'"}],staticClass:"centrar font-italic",style:(("opacity:0;\n          padding: 20px;\n          background-color: rgba(192,129,66, 0.9);\n          color: white;\n          " + (_vm.isMobile
              ? 'border-radius: 0px 0px 0px 0px;'
              : 'border-radius: 15px 0px 0px 15px;') + "            \n          //border: white 3px solid;\n        "))},[_c('span',{staticClass:"text-subtitle-1 font-bold"},[_vm._v(_vm._s(_vm.pagina.banner2.texto1))]),_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.pagina.banner2.texto2))]),_c('span',{staticClass:"text-subtitle-1",staticStyle:{"align-self":"flex-end"}},[_vm._v(_vm._s(_vm.pagina.banner2.texto3))])])])],1),_c('div',{staticStyle:{"width":"100%","margin-top":"50px"}},[_c('span',{staticClass:"font-weight-bold text-h5 text-md-h4",style:('color: ' + _vm.colors[0])},[_vm._v(_vm._s(_vm.pagina.seccion3.titulo))]),_c('v-container',{staticStyle:{"margin-top":"10px"}},[_c('v-row',_vm._l((_vm.pagina.seccion3.pasos),function(paso,i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"6","lg":"3"}},[_c('div',{directives:[{name:"animate-onscroll",rawName:"v-animate-onscroll",value:({
              down: 'animated fadeInLeft',
            }),expression:"{\n              down: 'animated fadeInLeft',\n            }"}],staticClass:"centrar",staticStyle:{"opacity":"0"}},[_c('div',{staticClass:"centrar",staticStyle:{"border":"#438019 4px solid","border-radius":"50%","width":"70px","height":"70px"}},[_c('v-icon',{staticClass:"text-h5 text-md-h4",attrs:{"color":"#af5116"}},[_vm._v(" mdi-"+_vm._s(paso.icono)+" ")])],1),_c('span',{staticClass:"font-weight-black text-h6 text-md-h5",staticStyle:{"margin-top":"15px"}},[_vm._v(" "+_vm._s(paso.titulo.toUpperCase())+" ")]),_c('span',{staticClass:"text-md-h6",staticStyle:{"margin-top":"5px"}},[_vm._v(" "+_vm._s(paso.descrip)+" ")])])])}),1)],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }